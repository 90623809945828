import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
import { WatchNowOfferFragmentDoc } from '../fragments/WatchNowOffer.fragment';
import { EditorialListTitleFragmentDoc } from '../fragments/EditorialListTitle.fragment';
export type GetArticleByUrlQueryVariables = Types.Exact<{
	fullPath: Types.Scalars['String']['input'];
	country: Types.Scalars['Country']['input'];
	language: Types.Scalars['Language']['input'];
	first: Types.Scalars['Int']['input'];
	filter?: Types.InputMaybe<Types.TitleFilter>;
	after?: Types.InputMaybe<Types.Scalars['String']['input']>;
	profile?: Types.InputMaybe<Types.BackdropProfile>;
	format?: Types.InputMaybe<Types.ImageFormat>;
}>;

export type GetArticleByUrlQuery = {
	__typename?: 'Query';
	urlV2: {
		__typename?: 'Url';
		id: string;
		node:
			| {
					__typename?: 'Article';
					id: string;
					author: {
						__typename?: 'Author';
						id: string;
						content: {
							__typename?: 'AuthorContent';
							bio: string;
							clearName: string;
							imageUrl?: string | null;
							fullPath: string;
							alumniOf: Array<string>;
							brand: string;
							languagesSpoken: Array<any>;
							occupation: Array<string>;
							publications?: Array<{
								__typename?: 'AuthorSpecificLink';
								name: string;
								url: string;
							}> | null;
							socialHandles?: Array<{
								__typename?: 'AuthorSpecificLink';
								name: string;
								url: string;
							}> | null;
						};
					};
					content: {
						__typename?: 'ArticleContent';
						title?: string | null;
						subtitle?: string | null;
						paragraph1?: string | null;
						paragraph2?: string | null;
						synopsis?: string | null;
						whereToWatch?: string | null;
						whatToKnow?: string | null;
						productionNews?: string | null;
						articleType: Types.ArticleType;
						createdAt: any;
						updatedAt: any;
						mainImageEntityBackdrop?: { __typename?: 'Backdrop'; backdropUrl: string } | null;
					};
					mainObjects: Array<{
						__typename: 'ArticleLinkedObject';
						node:
							| { __typename: 'Article' }
							| { __typename: 'Author' }
							| { __typename: 'Bundle' }
							| { __typename: 'Device' }
							| { __typename: 'Episode' }
							| {
									__typename: 'GenericTitleList';
									id: string;
									name: string;
									followedlistEntry?: { __typename?: 'FollowedListEntry'; createdAt: any } | null;
									titles: {
										__typename?: 'GenericTitleListConnection';
										totalCount: number;
										pageInfo: {
											__typename?: 'PageInfo';
											endCursor: string;
											hasNextPage: boolean;
											hasPreviousPage: boolean;
											startCursor: string;
										};
										edges?: Array<{
											__typename?: 'GenericTitleListEdge';
											node:
												| {
														__typename?: 'Movie';
														id: string;
														objectId: number;
														objectType: Types.ObjectType;
														seenlistEntry?: {
															__typename?: 'TitleListEntry';
															createdAt: any;
														} | null;
														content: {
															__typename?: 'MovieContent';
															title: string;
															fullPath: string;
															originalReleaseYear?: number | null;
															runtime?: number | null;
															shortDescription?: string | null;
															posterUrl?: string | null;
															isReleased: boolean;
															genres: Array<{ __typename?: 'Genre'; shortName: string }>;
															scoring: {
																__typename?: 'Scoring';
																imdbScore?: number | null;
																imdbVotes?: number | null;
																tmdbPopularity?: number | null;
																tmdbScore?: number | null;
															};
															backdrops: Array<{
																__typename?: 'Backdrop';
																backdropUrl: string;
															}>;
														};
														watchNowOffer?: {
															__typename: 'Offer';
															id: string;
															standardWebURL?: string | null;
															preAffiliatedStandardWebURL?: string | null;
															streamUrl?: string | null;
															retailPrice?: string | null;
															retailPriceValue?: number | null;
															lastChangeRetailPriceValue?: number | null;
															currency?: string | null;
															presentationType: Types.PresentationType;
															monetizationType: Types.MonetizationType;
															availableTo?: any | null;
															dateCreated?: any | null;
															newElementCount?: number | null;
															package: {
																__typename?: 'Package';
																id: string;
																icon: string;
																packageId: number;
																clearName: string;
																shortName: string;
																technicalName: string;
																iconWide: string;
																hasRectangularIcon: boolean;
															};
														} | null;
														watchlistEntryV2?: {
															__typename?: 'TitleListEntry';
															createdAt: any;
														} | null;
														likelistEntry?: {
															__typename?: 'TitleListEntry';
															createdAt: any;
														} | null;
														dislikelistEntry?: {
															__typename?: 'TitleListEntry';
															createdAt: any;
														} | null;
														offers: Array<{
															__typename?: 'Offer';
															id: string;
															currency?: string | null;
															monetizationType: Types.MonetizationType;
															presentationType: Types.PresentationType;
															retailPrice?: string | null;
															standardWebURL?: string | null;
															preAffiliatedStandardWebURL?: string | null;
															package: {
																__typename?: 'Package';
																shortName: string;
																id: string;
															};
														}>;
												  }
												| {
														__typename?: 'Show';
														id: string;
														objectId: number;
														objectType: Types.ObjectType;
														seenState: {
															__typename?: 'ShowSeenState';
															progress: number;
															seenEpisodeCount: number;
														};
														content: {
															__typename?: 'ShowContent';
															title: string;
															fullPath: string;
															originalReleaseYear?: number | null;
															runtime?: number | null;
															shortDescription?: string | null;
															posterUrl?: string | null;
															isReleased: boolean;
															genres: Array<{ __typename?: 'Genre'; shortName: string }>;
															scoring: {
																__typename?: 'Scoring';
																imdbScore?: number | null;
																imdbVotes?: number | null;
																tmdbPopularity?: number | null;
																tmdbScore?: number | null;
															};
															backdrops: Array<{
																__typename?: 'Backdrop';
																backdropUrl: string;
															}>;
														};
														watchNowOffer?: {
															__typename: 'Offer';
															id: string;
															standardWebURL?: string | null;
															preAffiliatedStandardWebURL?: string | null;
															streamUrl?: string | null;
															retailPrice?: string | null;
															retailPriceValue?: number | null;
															lastChangeRetailPriceValue?: number | null;
															currency?: string | null;
															presentationType: Types.PresentationType;
															monetizationType: Types.MonetizationType;
															availableTo?: any | null;
															dateCreated?: any | null;
															newElementCount?: number | null;
															package: {
																__typename?: 'Package';
																id: string;
																icon: string;
																packageId: number;
																clearName: string;
																shortName: string;
																technicalName: string;
																iconWide: string;
																hasRectangularIcon: boolean;
															};
														} | null;
														watchlistEntryV2?: {
															__typename?: 'TitleListEntry';
															createdAt: any;
														} | null;
														likelistEntry?: {
															__typename?: 'TitleListEntry';
															createdAt: any;
														} | null;
														dislikelistEntry?: {
															__typename?: 'TitleListEntry';
															createdAt: any;
														} | null;
														offers: Array<{
															__typename?: 'Offer';
															id: string;
															currency?: string | null;
															monetizationType: Types.MonetizationType;
															presentationType: Types.PresentationType;
															retailPrice?: string | null;
															standardWebURL?: string | null;
															preAffiliatedStandardWebURL?: string | null;
															package: {
																__typename?: 'Package';
																shortName: string;
																id: string;
															};
														}>;
												  };
										}> | null;
									};
							  }
							| { __typename: 'Genre' }
							| {
									__typename: 'Movie';
									id: string;
									objectId: number;
									objectType: Types.ObjectType;
									watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
									content: {
										__typename?: 'MovieContent';
										title: string;
										fullPath: string;
										originalReleaseYear?: number | null;
										runtime?: number | null;
										posterUrl?: string | null;
										isReleased: boolean;
										credits: Array<{
											__typename?: 'Credit';
											role: Types.CreditRole;
											name: string;
											characterName?: string | null;
											personId: number;
										}>;
										genres: Array<{ __typename?: 'Genre'; shortName: string }>;
										scoring: {
											__typename?: 'Scoring';
											imdbScore?: number | null;
											imdbVotes?: number | null;
											tmdbPopularity?: number | null;
											tmdbScore?: number | null;
										};
										externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
										backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
										clips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
										videobusterClips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
										dailymotionClips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
									};
									fallBackClips: {
										__typename?: 'MovieContent';
										videobusterClips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
										dailymotionClips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
									};
									watchNowOffer?: {
										__typename: 'Offer';
										id: string;
										standardWebURL?: string | null;
										preAffiliatedStandardWebURL?: string | null;
										streamUrl?: string | null;
										retailPrice?: string | null;
										retailPriceValue?: number | null;
										lastChangeRetailPriceValue?: number | null;
										currency?: string | null;
										presentationType: Types.PresentationType;
										monetizationType: Types.MonetizationType;
										availableTo?: any | null;
										dateCreated?: any | null;
										newElementCount?: number | null;
										package: {
											__typename?: 'Package';
											id: string;
											icon: string;
											packageId: number;
											clearName: string;
											shortName: string;
											technicalName: string;
											iconWide: string;
											hasRectangularIcon: boolean;
										};
									} | null;
							  }
							| { __typename: 'MultiStepSportEvent' }
							| { __typename: 'Offer' }
							| { __typename: 'Package' }
							| { __typename: 'Page' }
							| { __typename: 'Person' }
							| {
									__typename: 'Season';
									id: string;
									objectId: number;
									objectType: Types.ObjectType;
									content: {
										__typename?: 'SeasonContent';
										title: string;
										fullPath: string;
										originalReleaseYear?: number | null;
										runtime?: number | null;
										posterUrl?: string | null;
										isReleased: boolean;
										genres: Array<{ __typename?: 'Genre'; shortName: string }>;
										scoring: {
											__typename?: 'Scoring';
											imdbScore?: number | null;
											imdbVotes?: number | null;
											tmdbPopularity?: number | null;
											tmdbScore?: number | null;
										};
										externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
										backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
										clips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
										videobusterClips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
										dailymotionClips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
									};
									fallBackClips: {
										__typename?: 'SeasonContent';
										videobusterClips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
										dailymotionClips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
									};
									watchNowOffer?: {
										__typename: 'Offer';
										id: string;
										standardWebURL?: string | null;
										preAffiliatedStandardWebURL?: string | null;
										streamUrl?: string | null;
										retailPrice?: string | null;
										retailPriceValue?: number | null;
										lastChangeRetailPriceValue?: number | null;
										currency?: string | null;
										presentationType: Types.PresentationType;
										monetizationType: Types.MonetizationType;
										availableTo?: any | null;
										dateCreated?: any | null;
										newElementCount?: number | null;
										package: {
											__typename?: 'Package';
											id: string;
											icon: string;
											packageId: number;
											clearName: string;
											shortName: string;
											technicalName: string;
											iconWide: string;
											hasRectangularIcon: boolean;
										};
									} | null;
							  }
							| {
									__typename: 'Show';
									id: string;
									objectId: number;
									objectType: Types.ObjectType;
									watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
									content: {
										__typename?: 'ShowContent';
										title: string;
										fullPath: string;
										originalReleaseYear?: number | null;
										runtime?: number | null;
										posterUrl?: string | null;
										isReleased: boolean;
										credits: Array<{
											__typename?: 'Credit';
											role: Types.CreditRole;
											name: string;
											characterName?: string | null;
											personId: number;
										}>;
										genres: Array<{ __typename?: 'Genre'; shortName: string }>;
										scoring: {
											__typename?: 'Scoring';
											imdbScore?: number | null;
											imdbVotes?: number | null;
											tmdbPopularity?: number | null;
											tmdbScore?: number | null;
										};
										externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
										backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
										clips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
										videobusterClips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
										dailymotionClips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
									};
									fallBackClips: {
										__typename?: 'ShowContent';
										videobusterClips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
										dailymotionClips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
									};
									watchNowOffer?: {
										__typename: 'Offer';
										id: string;
										standardWebURL?: string | null;
										preAffiliatedStandardWebURL?: string | null;
										streamUrl?: string | null;
										retailPrice?: string | null;
										retailPriceValue?: number | null;
										lastChangeRetailPriceValue?: number | null;
										currency?: string | null;
										presentationType: Types.PresentationType;
										monetizationType: Types.MonetizationType;
										availableTo?: any | null;
										dateCreated?: any | null;
										newElementCount?: number | null;
										package: {
											__typename?: 'Package';
											id: string;
											icon: string;
											packageId: number;
											clearName: string;
											shortName: string;
											technicalName: string;
											iconWide: string;
											hasRectangularIcon: boolean;
										};
									} | null;
							  }
							| { __typename: 'SingleStepSportEvent' }
							| { __typename: 'SportCompetitionV2' }
							| { __typename: 'SportCompetitorV2' }
							| { __typename: 'Url' };
					}>;
					secondaryObjects: Array<{
						__typename?: 'ArticleLinkedObject';
						node:
							| { __typename?: 'Article' }
							| { __typename?: 'Author' }
							| { __typename?: 'Bundle' }
							| { __typename?: 'Device' }
							| { __typename?: 'Episode' }
							| {
									__typename: 'GenericTitleList';
									id: string;
									name: string;
									followedlistEntry?: { __typename?: 'FollowedListEntry'; createdAt: any } | null;
									titles: {
										__typename?: 'GenericTitleListConnection';
										totalCount: number;
										pageInfo: {
											__typename?: 'PageInfo';
											endCursor: string;
											hasNextPage: boolean;
											hasPreviousPage: boolean;
											startCursor: string;
										};
										edges?: Array<{
											__typename?: 'GenericTitleListEdge';
											node:
												| {
														__typename?: 'Movie';
														id: string;
														objectId: number;
														objectType: Types.ObjectType;
														seenlistEntry?: {
															__typename?: 'TitleListEntry';
															createdAt: any;
														} | null;
														content: {
															__typename?: 'MovieContent';
															title: string;
															fullPath: string;
															originalReleaseYear?: number | null;
															runtime?: number | null;
															shortDescription?: string | null;
															posterUrl?: string | null;
															isReleased: boolean;
															genres: Array<{ __typename?: 'Genre'; shortName: string }>;
															scoring: {
																__typename?: 'Scoring';
																imdbScore?: number | null;
																imdbVotes?: number | null;
																tmdbPopularity?: number | null;
																tmdbScore?: number | null;
															};
															backdrops: Array<{
																__typename?: 'Backdrop';
																backdropUrl: string;
															}>;
														};
														watchNowOffer?: {
															__typename: 'Offer';
															id: string;
															standardWebURL?: string | null;
															preAffiliatedStandardWebURL?: string | null;
															streamUrl?: string | null;
															retailPrice?: string | null;
															retailPriceValue?: number | null;
															lastChangeRetailPriceValue?: number | null;
															currency?: string | null;
															presentationType: Types.PresentationType;
															monetizationType: Types.MonetizationType;
															availableTo?: any | null;
															dateCreated?: any | null;
															newElementCount?: number | null;
															package: {
																__typename?: 'Package';
																id: string;
																icon: string;
																packageId: number;
																clearName: string;
																shortName: string;
																technicalName: string;
																iconWide: string;
																hasRectangularIcon: boolean;
															};
														} | null;
														watchlistEntryV2?: {
															__typename?: 'TitleListEntry';
															createdAt: any;
														} | null;
														likelistEntry?: {
															__typename?: 'TitleListEntry';
															createdAt: any;
														} | null;
														dislikelistEntry?: {
															__typename?: 'TitleListEntry';
															createdAt: any;
														} | null;
														offers: Array<{
															__typename?: 'Offer';
															id: string;
															currency?: string | null;
															monetizationType: Types.MonetizationType;
															presentationType: Types.PresentationType;
															retailPrice?: string | null;
															standardWebURL?: string | null;
															preAffiliatedStandardWebURL?: string | null;
															package: {
																__typename?: 'Package';
																shortName: string;
																id: string;
															};
														}>;
												  }
												| {
														__typename?: 'Show';
														id: string;
														objectId: number;
														objectType: Types.ObjectType;
														seenState: {
															__typename?: 'ShowSeenState';
															progress: number;
															seenEpisodeCount: number;
														};
														content: {
															__typename?: 'ShowContent';
															title: string;
															fullPath: string;
															originalReleaseYear?: number | null;
															runtime?: number | null;
															shortDescription?: string | null;
															posterUrl?: string | null;
															isReleased: boolean;
															genres: Array<{ __typename?: 'Genre'; shortName: string }>;
															scoring: {
																__typename?: 'Scoring';
																imdbScore?: number | null;
																imdbVotes?: number | null;
																tmdbPopularity?: number | null;
																tmdbScore?: number | null;
															};
															backdrops: Array<{
																__typename?: 'Backdrop';
																backdropUrl: string;
															}>;
														};
														watchNowOffer?: {
															__typename: 'Offer';
															id: string;
															standardWebURL?: string | null;
															preAffiliatedStandardWebURL?: string | null;
															streamUrl?: string | null;
															retailPrice?: string | null;
															retailPriceValue?: number | null;
															lastChangeRetailPriceValue?: number | null;
															currency?: string | null;
															presentationType: Types.PresentationType;
															monetizationType: Types.MonetizationType;
															availableTo?: any | null;
															dateCreated?: any | null;
															newElementCount?: number | null;
															package: {
																__typename?: 'Package';
																id: string;
																icon: string;
																packageId: number;
																clearName: string;
																shortName: string;
																technicalName: string;
																iconWide: string;
																hasRectangularIcon: boolean;
															};
														} | null;
														watchlistEntryV2?: {
															__typename?: 'TitleListEntry';
															createdAt: any;
														} | null;
														likelistEntry?: {
															__typename?: 'TitleListEntry';
															createdAt: any;
														} | null;
														dislikelistEntry?: {
															__typename?: 'TitleListEntry';
															createdAt: any;
														} | null;
														offers: Array<{
															__typename?: 'Offer';
															id: string;
															currency?: string | null;
															monetizationType: Types.MonetizationType;
															presentationType: Types.PresentationType;
															retailPrice?: string | null;
															standardWebURL?: string | null;
															preAffiliatedStandardWebURL?: string | null;
															package: {
																__typename?: 'Package';
																shortName: string;
																id: string;
															};
														}>;
												  };
										}> | null;
									};
							  }
							| { __typename?: 'Genre' }
							| {
									__typename: 'Movie';
									id: string;
									objectId: number;
									objectType: Types.ObjectType;
									watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
									content: {
										__typename?: 'MovieContent';
										title: string;
										fullPath: string;
										originalReleaseYear?: number | null;
										runtime?: number | null;
										posterUrl?: string | null;
										isReleased: boolean;
										credits: Array<{
											__typename?: 'Credit';
											role: Types.CreditRole;
											name: string;
											characterName?: string | null;
											personId: number;
										}>;
										genres: Array<{ __typename?: 'Genre'; shortName: string }>;
										scoring: {
											__typename?: 'Scoring';
											imdbScore?: number | null;
											imdbVotes?: number | null;
											tmdbPopularity?: number | null;
											tmdbScore?: number | null;
										};
										externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
										backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
										clips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
										videobusterClips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
										dailymotionClips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
									};
									fallBackClips: {
										__typename?: 'MovieContent';
										videobusterClips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
										dailymotionClips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
									};
									watchNowOffer?: {
										__typename: 'Offer';
										id: string;
										standardWebURL?: string | null;
										preAffiliatedStandardWebURL?: string | null;
										streamUrl?: string | null;
										retailPrice?: string | null;
										retailPriceValue?: number | null;
										lastChangeRetailPriceValue?: number | null;
										currency?: string | null;
										presentationType: Types.PresentationType;
										monetizationType: Types.MonetizationType;
										availableTo?: any | null;
										dateCreated?: any | null;
										newElementCount?: number | null;
										package: {
											__typename?: 'Package';
											id: string;
											icon: string;
											packageId: number;
											clearName: string;
											shortName: string;
											technicalName: string;
											iconWide: string;
											hasRectangularIcon: boolean;
										};
									} | null;
							  }
							| { __typename?: 'MultiStepSportEvent' }
							| { __typename?: 'Offer' }
							| { __typename?: 'Package' }
							| { __typename?: 'Page' }
							| { __typename?: 'Person' }
							| {
									__typename: 'Season';
									id: string;
									objectId: number;
									objectType: Types.ObjectType;
									content: {
										__typename?: 'SeasonContent';
										title: string;
										fullPath: string;
										originalReleaseYear?: number | null;
										runtime?: number | null;
										posterUrl?: string | null;
										isReleased: boolean;
										genres: Array<{ __typename?: 'Genre'; shortName: string }>;
										scoring: {
											__typename?: 'Scoring';
											imdbScore?: number | null;
											imdbVotes?: number | null;
											tmdbPopularity?: number | null;
											tmdbScore?: number | null;
										};
										externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
										backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
										clips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
										videobusterClips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
										dailymotionClips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
									};
									fallBackClips: {
										__typename?: 'SeasonContent';
										videobusterClips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
										dailymotionClips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
									};
									watchNowOffer?: {
										__typename: 'Offer';
										id: string;
										standardWebURL?: string | null;
										preAffiliatedStandardWebURL?: string | null;
										streamUrl?: string | null;
										retailPrice?: string | null;
										retailPriceValue?: number | null;
										lastChangeRetailPriceValue?: number | null;
										currency?: string | null;
										presentationType: Types.PresentationType;
										monetizationType: Types.MonetizationType;
										availableTo?: any | null;
										dateCreated?: any | null;
										newElementCount?: number | null;
										package: {
											__typename?: 'Package';
											id: string;
											icon: string;
											packageId: number;
											clearName: string;
											shortName: string;
											technicalName: string;
											iconWide: string;
											hasRectangularIcon: boolean;
										};
									} | null;
							  }
							| {
									__typename: 'Show';
									id: string;
									objectId: number;
									objectType: Types.ObjectType;
									watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
									content: {
										__typename?: 'ShowContent';
										title: string;
										fullPath: string;
										originalReleaseYear?: number | null;
										runtime?: number | null;
										posterUrl?: string | null;
										isReleased: boolean;
										credits: Array<{
											__typename?: 'Credit';
											role: Types.CreditRole;
											name: string;
											characterName?: string | null;
											personId: number;
										}>;
										genres: Array<{ __typename?: 'Genre'; shortName: string }>;
										scoring: {
											__typename?: 'Scoring';
											imdbScore?: number | null;
											imdbVotes?: number | null;
											tmdbPopularity?: number | null;
											tmdbScore?: number | null;
										};
										externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
										backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
										clips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
										videobusterClips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
										dailymotionClips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
									};
									fallBackClips: {
										__typename?: 'ShowContent';
										videobusterClips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
										dailymotionClips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
									};
									watchNowOffer?: {
										__typename: 'Offer';
										id: string;
										standardWebURL?: string | null;
										preAffiliatedStandardWebURL?: string | null;
										streamUrl?: string | null;
										retailPrice?: string | null;
										retailPriceValue?: number | null;
										lastChangeRetailPriceValue?: number | null;
										currency?: string | null;
										presentationType: Types.PresentationType;
										monetizationType: Types.MonetizationType;
										availableTo?: any | null;
										dateCreated?: any | null;
										newElementCount?: number | null;
										package: {
											__typename?: 'Package';
											id: string;
											icon: string;
											packageId: number;
											clearName: string;
											shortName: string;
											technicalName: string;
											iconWide: string;
											hasRectangularIcon: boolean;
										};
									} | null;
							  }
							| { __typename?: 'SingleStepSportEvent' }
							| { __typename?: 'SportCompetitionV2' }
							| { __typename?: 'SportCompetitorV2' }
							| { __typename?: 'Url' };
					}>;
					supportingObjects: Array<{
						__typename?: 'ArticleLinkedObject';
						node:
							| { __typename?: 'Article' }
							| { __typename?: 'Author' }
							| { __typename?: 'Bundle' }
							| { __typename?: 'Device' }
							| { __typename?: 'Episode' }
							| {
									__typename: 'GenericTitleList';
									id: string;
									name: string;
									followedlistEntry?: { __typename?: 'FollowedListEntry'; createdAt: any } | null;
									titles: {
										__typename?: 'GenericTitleListConnection';
										totalCount: number;
										pageInfo: {
											__typename?: 'PageInfo';
											endCursor: string;
											hasNextPage: boolean;
											hasPreviousPage: boolean;
											startCursor: string;
										};
										edges?: Array<{
											__typename?: 'GenericTitleListEdge';
											node:
												| {
														__typename?: 'Movie';
														id: string;
														objectId: number;
														objectType: Types.ObjectType;
														seenlistEntry?: {
															__typename?: 'TitleListEntry';
															createdAt: any;
														} | null;
														content: {
															__typename?: 'MovieContent';
															title: string;
															fullPath: string;
															originalReleaseYear?: number | null;
															runtime?: number | null;
															shortDescription?: string | null;
															posterUrl?: string | null;
															isReleased: boolean;
															genres: Array<{ __typename?: 'Genre'; shortName: string }>;
															scoring: {
																__typename?: 'Scoring';
																imdbScore?: number | null;
																imdbVotes?: number | null;
																tmdbPopularity?: number | null;
																tmdbScore?: number | null;
															};
															backdrops: Array<{
																__typename?: 'Backdrop';
																backdropUrl: string;
															}>;
														};
														watchNowOffer?: {
															__typename: 'Offer';
															id: string;
															standardWebURL?: string | null;
															preAffiliatedStandardWebURL?: string | null;
															streamUrl?: string | null;
															retailPrice?: string | null;
															retailPriceValue?: number | null;
															lastChangeRetailPriceValue?: number | null;
															currency?: string | null;
															presentationType: Types.PresentationType;
															monetizationType: Types.MonetizationType;
															availableTo?: any | null;
															dateCreated?: any | null;
															newElementCount?: number | null;
															package: {
																__typename?: 'Package';
																id: string;
																icon: string;
																packageId: number;
																clearName: string;
																shortName: string;
																technicalName: string;
																iconWide: string;
																hasRectangularIcon: boolean;
															};
														} | null;
														watchlistEntryV2?: {
															__typename?: 'TitleListEntry';
															createdAt: any;
														} | null;
														likelistEntry?: {
															__typename?: 'TitleListEntry';
															createdAt: any;
														} | null;
														dislikelistEntry?: {
															__typename?: 'TitleListEntry';
															createdAt: any;
														} | null;
														offers: Array<{
															__typename?: 'Offer';
															id: string;
															currency?: string | null;
															monetizationType: Types.MonetizationType;
															presentationType: Types.PresentationType;
															retailPrice?: string | null;
															standardWebURL?: string | null;
															preAffiliatedStandardWebURL?: string | null;
															package: {
																__typename?: 'Package';
																shortName: string;
																id: string;
															};
														}>;
												  }
												| {
														__typename?: 'Show';
														id: string;
														objectId: number;
														objectType: Types.ObjectType;
														seenState: {
															__typename?: 'ShowSeenState';
															progress: number;
															seenEpisodeCount: number;
														};
														content: {
															__typename?: 'ShowContent';
															title: string;
															fullPath: string;
															originalReleaseYear?: number | null;
															runtime?: number | null;
															shortDescription?: string | null;
															posterUrl?: string | null;
															isReleased: boolean;
															genres: Array<{ __typename?: 'Genre'; shortName: string }>;
															scoring: {
																__typename?: 'Scoring';
																imdbScore?: number | null;
																imdbVotes?: number | null;
																tmdbPopularity?: number | null;
																tmdbScore?: number | null;
															};
															backdrops: Array<{
																__typename?: 'Backdrop';
																backdropUrl: string;
															}>;
														};
														watchNowOffer?: {
															__typename: 'Offer';
															id: string;
															standardWebURL?: string | null;
															preAffiliatedStandardWebURL?: string | null;
															streamUrl?: string | null;
															retailPrice?: string | null;
															retailPriceValue?: number | null;
															lastChangeRetailPriceValue?: number | null;
															currency?: string | null;
															presentationType: Types.PresentationType;
															monetizationType: Types.MonetizationType;
															availableTo?: any | null;
															dateCreated?: any | null;
															newElementCount?: number | null;
															package: {
																__typename?: 'Package';
																id: string;
																icon: string;
																packageId: number;
																clearName: string;
																shortName: string;
																technicalName: string;
																iconWide: string;
																hasRectangularIcon: boolean;
															};
														} | null;
														watchlistEntryV2?: {
															__typename?: 'TitleListEntry';
															createdAt: any;
														} | null;
														likelistEntry?: {
															__typename?: 'TitleListEntry';
															createdAt: any;
														} | null;
														dislikelistEntry?: {
															__typename?: 'TitleListEntry';
															createdAt: any;
														} | null;
														offers: Array<{
															__typename?: 'Offer';
															id: string;
															currency?: string | null;
															monetizationType: Types.MonetizationType;
															presentationType: Types.PresentationType;
															retailPrice?: string | null;
															standardWebURL?: string | null;
															preAffiliatedStandardWebURL?: string | null;
															package: {
																__typename?: 'Package';
																shortName: string;
																id: string;
															};
														}>;
												  };
										}> | null;
									};
							  }
							| { __typename?: 'Genre' }
							| {
									__typename: 'Movie';
									id: string;
									objectId: number;
									objectType: Types.ObjectType;
									watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
									content: {
										__typename?: 'MovieContent';
										title: string;
										fullPath: string;
										originalReleaseYear?: number | null;
										runtime?: number | null;
										posterUrl?: string | null;
										isReleased: boolean;
										credits: Array<{
											__typename?: 'Credit';
											role: Types.CreditRole;
											name: string;
											characterName?: string | null;
											personId: number;
										}>;
										genres: Array<{ __typename?: 'Genre'; shortName: string }>;
										scoring: {
											__typename?: 'Scoring';
											imdbScore?: number | null;
											imdbVotes?: number | null;
											tmdbPopularity?: number | null;
											tmdbScore?: number | null;
										};
										externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
										backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
										clips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
										videobusterClips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
										dailymotionClips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
									};
									fallBackClips: {
										__typename?: 'MovieContent';
										videobusterClips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
										dailymotionClips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
									};
									watchNowOffer?: {
										__typename: 'Offer';
										id: string;
										standardWebURL?: string | null;
										preAffiliatedStandardWebURL?: string | null;
										streamUrl?: string | null;
										retailPrice?: string | null;
										retailPriceValue?: number | null;
										lastChangeRetailPriceValue?: number | null;
										currency?: string | null;
										presentationType: Types.PresentationType;
										monetizationType: Types.MonetizationType;
										availableTo?: any | null;
										dateCreated?: any | null;
										newElementCount?: number | null;
										package: {
											__typename?: 'Package';
											id: string;
											icon: string;
											packageId: number;
											clearName: string;
											shortName: string;
											technicalName: string;
											iconWide: string;
											hasRectangularIcon: boolean;
										};
									} | null;
							  }
							| { __typename?: 'MultiStepSportEvent' }
							| { __typename?: 'Offer' }
							| { __typename?: 'Package' }
							| { __typename?: 'Page' }
							| { __typename?: 'Person' }
							| { __typename?: 'Season' }
							| {
									__typename: 'Show';
									id: string;
									objectId: number;
									objectType: Types.ObjectType;
									watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
									content: {
										__typename?: 'ShowContent';
										title: string;
										fullPath: string;
										originalReleaseYear?: number | null;
										runtime?: number | null;
										posterUrl?: string | null;
										isReleased: boolean;
										credits: Array<{
											__typename?: 'Credit';
											role: Types.CreditRole;
											name: string;
											characterName?: string | null;
											personId: number;
										}>;
										genres: Array<{ __typename?: 'Genre'; shortName: string }>;
										scoring: {
											__typename?: 'Scoring';
											imdbScore?: number | null;
											imdbVotes?: number | null;
											tmdbPopularity?: number | null;
											tmdbScore?: number | null;
										};
										externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
										backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
										clips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
										videobusterClips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
										dailymotionClips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
									};
									fallBackClips: {
										__typename?: 'ShowContent';
										videobusterClips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
										dailymotionClips: Array<{
											__typename?: 'Clip';
											sourceUrl: string;
											externalId: string;
											provider: Types.ClipProvider;
										}>;
									};
									watchNowOffer?: {
										__typename: 'Offer';
										id: string;
										standardWebURL?: string | null;
										preAffiliatedStandardWebURL?: string | null;
										streamUrl?: string | null;
										retailPrice?: string | null;
										retailPriceValue?: number | null;
										lastChangeRetailPriceValue?: number | null;
										currency?: string | null;
										presentationType: Types.PresentationType;
										monetizationType: Types.MonetizationType;
										availableTo?: any | null;
										dateCreated?: any | null;
										newElementCount?: number | null;
										package: {
											__typename?: 'Package';
											id: string;
											icon: string;
											packageId: number;
											clearName: string;
											shortName: string;
											technicalName: string;
											iconWide: string;
											hasRectangularIcon: boolean;
										};
									} | null;
							  }
							| { __typename?: 'SingleStepSportEvent' }
							| { __typename?: 'SportCompetitionV2' }
							| { __typename?: 'SportCompetitorV2' }
							| { __typename?: 'Url' };
					}>;
			  }
			| { __typename?: 'Author'; id: string }
			| { __typename?: 'Bundle'; id: string }
			| { __typename?: 'Device'; id: string }
			| { __typename?: 'Episode'; id: string }
			| { __typename?: 'GenericTitleList'; id: string }
			| { __typename?: 'Genre'; id: string }
			| { __typename?: 'Movie'; id: string }
			| { __typename?: 'MultiStepSportEvent'; id: string }
			| { __typename?: 'Offer'; id: string }
			| { __typename?: 'Package'; id: string }
			| { __typename?: 'Page'; id: string }
			| { __typename?: 'Person'; id: string }
			| { __typename?: 'Season'; id: string }
			| { __typename?: 'Show'; id: string }
			| { __typename?: 'SingleStepSportEvent'; id: string }
			| { __typename?: 'SportCompetitionV2'; id: string }
			| { __typename?: 'SportCompetitorV2'; id: string }
			| { __typename?: 'Url'; id: string };
	};
};

export type ArticleFragment = {
	__typename?: 'Article';
	author: {
		__typename?: 'Author';
		id: string;
		content: {
			__typename?: 'AuthorContent';
			bio: string;
			clearName: string;
			imageUrl?: string | null;
			fullPath: string;
			alumniOf: Array<string>;
			brand: string;
			languagesSpoken: Array<any>;
			occupation: Array<string>;
			publications?: Array<{ __typename?: 'AuthorSpecificLink'; name: string; url: string }> | null;
			socialHandles?: Array<{ __typename?: 'AuthorSpecificLink'; name: string; url: string }> | null;
		};
	};
	content: {
		__typename?: 'ArticleContent';
		title?: string | null;
		subtitle?: string | null;
		paragraph1?: string | null;
		paragraph2?: string | null;
		synopsis?: string | null;
		whereToWatch?: string | null;
		whatToKnow?: string | null;
		productionNews?: string | null;
		articleType: Types.ArticleType;
		createdAt: any;
		updatedAt: any;
		mainImageEntityBackdrop?: { __typename?: 'Backdrop'; backdropUrl: string } | null;
	};
	mainObjects: Array<{
		__typename: 'ArticleLinkedObject';
		node:
			| { __typename: 'Article' }
			| { __typename: 'Author' }
			| { __typename: 'Bundle' }
			| { __typename: 'Device' }
			| { __typename: 'Episode' }
			| {
					__typename: 'GenericTitleList';
					id: string;
					name: string;
					followedlistEntry?: { __typename?: 'FollowedListEntry'; createdAt: any } | null;
					titles: {
						__typename?: 'GenericTitleListConnection';
						totalCount: number;
						pageInfo: {
							__typename?: 'PageInfo';
							endCursor: string;
							hasNextPage: boolean;
							hasPreviousPage: boolean;
							startCursor: string;
						};
						edges?: Array<{
							__typename?: 'GenericTitleListEdge';
							node:
								| {
										__typename?: 'Movie';
										id: string;
										objectId: number;
										objectType: Types.ObjectType;
										seenlistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
										content: {
											__typename?: 'MovieContent';
											title: string;
											fullPath: string;
											originalReleaseYear?: number | null;
											runtime?: number | null;
											shortDescription?: string | null;
											posterUrl?: string | null;
											isReleased: boolean;
											genres: Array<{ __typename?: 'Genre'; shortName: string }>;
											scoring: {
												__typename?: 'Scoring';
												imdbScore?: number | null;
												imdbVotes?: number | null;
												tmdbPopularity?: number | null;
												tmdbScore?: number | null;
											};
											backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
										};
										watchNowOffer?: {
											__typename: 'Offer';
											id: string;
											standardWebURL?: string | null;
											preAffiliatedStandardWebURL?: string | null;
											streamUrl?: string | null;
											retailPrice?: string | null;
											retailPriceValue?: number | null;
											lastChangeRetailPriceValue?: number | null;
											currency?: string | null;
											presentationType: Types.PresentationType;
											monetizationType: Types.MonetizationType;
											availableTo?: any | null;
											dateCreated?: any | null;
											newElementCount?: number | null;
											package: {
												__typename?: 'Package';
												id: string;
												icon: string;
												packageId: number;
												clearName: string;
												shortName: string;
												technicalName: string;
												iconWide: string;
												hasRectangularIcon: boolean;
											};
										} | null;
										watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
										likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
										dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
										offers: Array<{
											__typename?: 'Offer';
											id: string;
											currency?: string | null;
											monetizationType: Types.MonetizationType;
											presentationType: Types.PresentationType;
											retailPrice?: string | null;
											standardWebURL?: string | null;
											preAffiliatedStandardWebURL?: string | null;
											package: { __typename?: 'Package'; shortName: string; id: string };
										}>;
								  }
								| {
										__typename?: 'Show';
										id: string;
										objectId: number;
										objectType: Types.ObjectType;
										seenState: {
											__typename?: 'ShowSeenState';
											progress: number;
											seenEpisodeCount: number;
										};
										content: {
											__typename?: 'ShowContent';
											title: string;
											fullPath: string;
											originalReleaseYear?: number | null;
											runtime?: number | null;
											shortDescription?: string | null;
											posterUrl?: string | null;
											isReleased: boolean;
											genres: Array<{ __typename?: 'Genre'; shortName: string }>;
											scoring: {
												__typename?: 'Scoring';
												imdbScore?: number | null;
												imdbVotes?: number | null;
												tmdbPopularity?: number | null;
												tmdbScore?: number | null;
											};
											backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
										};
										watchNowOffer?: {
											__typename: 'Offer';
											id: string;
											standardWebURL?: string | null;
											preAffiliatedStandardWebURL?: string | null;
											streamUrl?: string | null;
											retailPrice?: string | null;
											retailPriceValue?: number | null;
											lastChangeRetailPriceValue?: number | null;
											currency?: string | null;
											presentationType: Types.PresentationType;
											monetizationType: Types.MonetizationType;
											availableTo?: any | null;
											dateCreated?: any | null;
											newElementCount?: number | null;
											package: {
												__typename?: 'Package';
												id: string;
												icon: string;
												packageId: number;
												clearName: string;
												shortName: string;
												technicalName: string;
												iconWide: string;
												hasRectangularIcon: boolean;
											};
										} | null;
										watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
										likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
										dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
										offers: Array<{
											__typename?: 'Offer';
											id: string;
											currency?: string | null;
											monetizationType: Types.MonetizationType;
											presentationType: Types.PresentationType;
											retailPrice?: string | null;
											standardWebURL?: string | null;
											preAffiliatedStandardWebURL?: string | null;
											package: { __typename?: 'Package'; shortName: string; id: string };
										}>;
								  };
						}> | null;
					};
			  }
			| { __typename: 'Genre' }
			| {
					__typename: 'Movie';
					id: string;
					objectId: number;
					objectType: Types.ObjectType;
					watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
					content: {
						__typename?: 'MovieContent';
						title: string;
						fullPath: string;
						originalReleaseYear?: number | null;
						runtime?: number | null;
						posterUrl?: string | null;
						isReleased: boolean;
						credits: Array<{
							__typename?: 'Credit';
							role: Types.CreditRole;
							name: string;
							characterName?: string | null;
							personId: number;
						}>;
						genres: Array<{ __typename?: 'Genre'; shortName: string }>;
						scoring: {
							__typename?: 'Scoring';
							imdbScore?: number | null;
							imdbVotes?: number | null;
							tmdbPopularity?: number | null;
							tmdbScore?: number | null;
						};
						externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
						backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
						clips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
						videobusterClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
						dailymotionClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
					};
					fallBackClips: {
						__typename?: 'MovieContent';
						videobusterClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
						dailymotionClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
					};
					watchNowOffer?: {
						__typename: 'Offer';
						id: string;
						standardWebURL?: string | null;
						preAffiliatedStandardWebURL?: string | null;
						streamUrl?: string | null;
						retailPrice?: string | null;
						retailPriceValue?: number | null;
						lastChangeRetailPriceValue?: number | null;
						currency?: string | null;
						presentationType: Types.PresentationType;
						monetizationType: Types.MonetizationType;
						availableTo?: any | null;
						dateCreated?: any | null;
						newElementCount?: number | null;
						package: {
							__typename?: 'Package';
							id: string;
							icon: string;
							packageId: number;
							clearName: string;
							shortName: string;
							technicalName: string;
							iconWide: string;
							hasRectangularIcon: boolean;
						};
					} | null;
			  }
			| { __typename: 'MultiStepSportEvent' }
			| { __typename: 'Offer' }
			| { __typename: 'Package' }
			| { __typename: 'Page' }
			| { __typename: 'Person' }
			| {
					__typename: 'Season';
					id: string;
					objectId: number;
					objectType: Types.ObjectType;
					content: {
						__typename?: 'SeasonContent';
						title: string;
						fullPath: string;
						originalReleaseYear?: number | null;
						runtime?: number | null;
						posterUrl?: string | null;
						isReleased: boolean;
						genres: Array<{ __typename?: 'Genre'; shortName: string }>;
						scoring: {
							__typename?: 'Scoring';
							imdbScore?: number | null;
							imdbVotes?: number | null;
							tmdbPopularity?: number | null;
							tmdbScore?: number | null;
						};
						externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
						backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
						clips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
						videobusterClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
						dailymotionClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
					};
					fallBackClips: {
						__typename?: 'SeasonContent';
						videobusterClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
						dailymotionClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
					};
					watchNowOffer?: {
						__typename: 'Offer';
						id: string;
						standardWebURL?: string | null;
						preAffiliatedStandardWebURL?: string | null;
						streamUrl?: string | null;
						retailPrice?: string | null;
						retailPriceValue?: number | null;
						lastChangeRetailPriceValue?: number | null;
						currency?: string | null;
						presentationType: Types.PresentationType;
						monetizationType: Types.MonetizationType;
						availableTo?: any | null;
						dateCreated?: any | null;
						newElementCount?: number | null;
						package: {
							__typename?: 'Package';
							id: string;
							icon: string;
							packageId: number;
							clearName: string;
							shortName: string;
							technicalName: string;
							iconWide: string;
							hasRectangularIcon: boolean;
						};
					} | null;
			  }
			| {
					__typename: 'Show';
					id: string;
					objectId: number;
					objectType: Types.ObjectType;
					watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
					content: {
						__typename?: 'ShowContent';
						title: string;
						fullPath: string;
						originalReleaseYear?: number | null;
						runtime?: number | null;
						posterUrl?: string | null;
						isReleased: boolean;
						credits: Array<{
							__typename?: 'Credit';
							role: Types.CreditRole;
							name: string;
							characterName?: string | null;
							personId: number;
						}>;
						genres: Array<{ __typename?: 'Genre'; shortName: string }>;
						scoring: {
							__typename?: 'Scoring';
							imdbScore?: number | null;
							imdbVotes?: number | null;
							tmdbPopularity?: number | null;
							tmdbScore?: number | null;
						};
						externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
						backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
						clips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
						videobusterClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
						dailymotionClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
					};
					fallBackClips: {
						__typename?: 'ShowContent';
						videobusterClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
						dailymotionClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
					};
					watchNowOffer?: {
						__typename: 'Offer';
						id: string;
						standardWebURL?: string | null;
						preAffiliatedStandardWebURL?: string | null;
						streamUrl?: string | null;
						retailPrice?: string | null;
						retailPriceValue?: number | null;
						lastChangeRetailPriceValue?: number | null;
						currency?: string | null;
						presentationType: Types.PresentationType;
						monetizationType: Types.MonetizationType;
						availableTo?: any | null;
						dateCreated?: any | null;
						newElementCount?: number | null;
						package: {
							__typename?: 'Package';
							id: string;
							icon: string;
							packageId: number;
							clearName: string;
							shortName: string;
							technicalName: string;
							iconWide: string;
							hasRectangularIcon: boolean;
						};
					} | null;
			  }
			| { __typename: 'SingleStepSportEvent' }
			| { __typename: 'SportCompetitionV2' }
			| { __typename: 'SportCompetitorV2' }
			| { __typename: 'Url' };
	}>;
	secondaryObjects: Array<{
		__typename?: 'ArticleLinkedObject';
		node:
			| { __typename?: 'Article' }
			| { __typename?: 'Author' }
			| { __typename?: 'Bundle' }
			| { __typename?: 'Device' }
			| { __typename?: 'Episode' }
			| {
					__typename: 'GenericTitleList';
					id: string;
					name: string;
					followedlistEntry?: { __typename?: 'FollowedListEntry'; createdAt: any } | null;
					titles: {
						__typename?: 'GenericTitleListConnection';
						totalCount: number;
						pageInfo: {
							__typename?: 'PageInfo';
							endCursor: string;
							hasNextPage: boolean;
							hasPreviousPage: boolean;
							startCursor: string;
						};
						edges?: Array<{
							__typename?: 'GenericTitleListEdge';
							node:
								| {
										__typename?: 'Movie';
										id: string;
										objectId: number;
										objectType: Types.ObjectType;
										seenlistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
										content: {
											__typename?: 'MovieContent';
											title: string;
											fullPath: string;
											originalReleaseYear?: number | null;
											runtime?: number | null;
											shortDescription?: string | null;
											posterUrl?: string | null;
											isReleased: boolean;
											genres: Array<{ __typename?: 'Genre'; shortName: string }>;
											scoring: {
												__typename?: 'Scoring';
												imdbScore?: number | null;
												imdbVotes?: number | null;
												tmdbPopularity?: number | null;
												tmdbScore?: number | null;
											};
											backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
										};
										watchNowOffer?: {
											__typename: 'Offer';
											id: string;
											standardWebURL?: string | null;
											preAffiliatedStandardWebURL?: string | null;
											streamUrl?: string | null;
											retailPrice?: string | null;
											retailPriceValue?: number | null;
											lastChangeRetailPriceValue?: number | null;
											currency?: string | null;
											presentationType: Types.PresentationType;
											monetizationType: Types.MonetizationType;
											availableTo?: any | null;
											dateCreated?: any | null;
											newElementCount?: number | null;
											package: {
												__typename?: 'Package';
												id: string;
												icon: string;
												packageId: number;
												clearName: string;
												shortName: string;
												technicalName: string;
												iconWide: string;
												hasRectangularIcon: boolean;
											};
										} | null;
										watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
										likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
										dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
										offers: Array<{
											__typename?: 'Offer';
											id: string;
											currency?: string | null;
											monetizationType: Types.MonetizationType;
											presentationType: Types.PresentationType;
											retailPrice?: string | null;
											standardWebURL?: string | null;
											preAffiliatedStandardWebURL?: string | null;
											package: { __typename?: 'Package'; shortName: string; id: string };
										}>;
								  }
								| {
										__typename?: 'Show';
										id: string;
										objectId: number;
										objectType: Types.ObjectType;
										seenState: {
											__typename?: 'ShowSeenState';
											progress: number;
											seenEpisodeCount: number;
										};
										content: {
											__typename?: 'ShowContent';
											title: string;
											fullPath: string;
											originalReleaseYear?: number | null;
											runtime?: number | null;
											shortDescription?: string | null;
											posterUrl?: string | null;
											isReleased: boolean;
											genres: Array<{ __typename?: 'Genre'; shortName: string }>;
											scoring: {
												__typename?: 'Scoring';
												imdbScore?: number | null;
												imdbVotes?: number | null;
												tmdbPopularity?: number | null;
												tmdbScore?: number | null;
											};
											backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
										};
										watchNowOffer?: {
											__typename: 'Offer';
											id: string;
											standardWebURL?: string | null;
											preAffiliatedStandardWebURL?: string | null;
											streamUrl?: string | null;
											retailPrice?: string | null;
											retailPriceValue?: number | null;
											lastChangeRetailPriceValue?: number | null;
											currency?: string | null;
											presentationType: Types.PresentationType;
											monetizationType: Types.MonetizationType;
											availableTo?: any | null;
											dateCreated?: any | null;
											newElementCount?: number | null;
											package: {
												__typename?: 'Package';
												id: string;
												icon: string;
												packageId: number;
												clearName: string;
												shortName: string;
												technicalName: string;
												iconWide: string;
												hasRectangularIcon: boolean;
											};
										} | null;
										watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
										likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
										dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
										offers: Array<{
											__typename?: 'Offer';
											id: string;
											currency?: string | null;
											monetizationType: Types.MonetizationType;
											presentationType: Types.PresentationType;
											retailPrice?: string | null;
											standardWebURL?: string | null;
											preAffiliatedStandardWebURL?: string | null;
											package: { __typename?: 'Package'; shortName: string; id: string };
										}>;
								  };
						}> | null;
					};
			  }
			| { __typename?: 'Genre' }
			| {
					__typename: 'Movie';
					id: string;
					objectId: number;
					objectType: Types.ObjectType;
					watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
					content: {
						__typename?: 'MovieContent';
						title: string;
						fullPath: string;
						originalReleaseYear?: number | null;
						runtime?: number | null;
						posterUrl?: string | null;
						isReleased: boolean;
						credits: Array<{
							__typename?: 'Credit';
							role: Types.CreditRole;
							name: string;
							characterName?: string | null;
							personId: number;
						}>;
						genres: Array<{ __typename?: 'Genre'; shortName: string }>;
						scoring: {
							__typename?: 'Scoring';
							imdbScore?: number | null;
							imdbVotes?: number | null;
							tmdbPopularity?: number | null;
							tmdbScore?: number | null;
						};
						externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
						backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
						clips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
						videobusterClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
						dailymotionClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
					};
					fallBackClips: {
						__typename?: 'MovieContent';
						videobusterClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
						dailymotionClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
					};
					watchNowOffer?: {
						__typename: 'Offer';
						id: string;
						standardWebURL?: string | null;
						preAffiliatedStandardWebURL?: string | null;
						streamUrl?: string | null;
						retailPrice?: string | null;
						retailPriceValue?: number | null;
						lastChangeRetailPriceValue?: number | null;
						currency?: string | null;
						presentationType: Types.PresentationType;
						monetizationType: Types.MonetizationType;
						availableTo?: any | null;
						dateCreated?: any | null;
						newElementCount?: number | null;
						package: {
							__typename?: 'Package';
							id: string;
							icon: string;
							packageId: number;
							clearName: string;
							shortName: string;
							technicalName: string;
							iconWide: string;
							hasRectangularIcon: boolean;
						};
					} | null;
			  }
			| { __typename?: 'MultiStepSportEvent' }
			| { __typename?: 'Offer' }
			| { __typename?: 'Package' }
			| { __typename?: 'Page' }
			| { __typename?: 'Person' }
			| {
					__typename: 'Season';
					id: string;
					objectId: number;
					objectType: Types.ObjectType;
					content: {
						__typename?: 'SeasonContent';
						title: string;
						fullPath: string;
						originalReleaseYear?: number | null;
						runtime?: number | null;
						posterUrl?: string | null;
						isReleased: boolean;
						genres: Array<{ __typename?: 'Genre'; shortName: string }>;
						scoring: {
							__typename?: 'Scoring';
							imdbScore?: number | null;
							imdbVotes?: number | null;
							tmdbPopularity?: number | null;
							tmdbScore?: number | null;
						};
						externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
						backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
						clips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
						videobusterClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
						dailymotionClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
					};
					fallBackClips: {
						__typename?: 'SeasonContent';
						videobusterClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
						dailymotionClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
					};
					watchNowOffer?: {
						__typename: 'Offer';
						id: string;
						standardWebURL?: string | null;
						preAffiliatedStandardWebURL?: string | null;
						streamUrl?: string | null;
						retailPrice?: string | null;
						retailPriceValue?: number | null;
						lastChangeRetailPriceValue?: number | null;
						currency?: string | null;
						presentationType: Types.PresentationType;
						monetizationType: Types.MonetizationType;
						availableTo?: any | null;
						dateCreated?: any | null;
						newElementCount?: number | null;
						package: {
							__typename?: 'Package';
							id: string;
							icon: string;
							packageId: number;
							clearName: string;
							shortName: string;
							technicalName: string;
							iconWide: string;
							hasRectangularIcon: boolean;
						};
					} | null;
			  }
			| {
					__typename: 'Show';
					id: string;
					objectId: number;
					objectType: Types.ObjectType;
					watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
					content: {
						__typename?: 'ShowContent';
						title: string;
						fullPath: string;
						originalReleaseYear?: number | null;
						runtime?: number | null;
						posterUrl?: string | null;
						isReleased: boolean;
						credits: Array<{
							__typename?: 'Credit';
							role: Types.CreditRole;
							name: string;
							characterName?: string | null;
							personId: number;
						}>;
						genres: Array<{ __typename?: 'Genre'; shortName: string }>;
						scoring: {
							__typename?: 'Scoring';
							imdbScore?: number | null;
							imdbVotes?: number | null;
							tmdbPopularity?: number | null;
							tmdbScore?: number | null;
						};
						externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
						backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
						clips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
						videobusterClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
						dailymotionClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
					};
					fallBackClips: {
						__typename?: 'ShowContent';
						videobusterClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
						dailymotionClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
					};
					watchNowOffer?: {
						__typename: 'Offer';
						id: string;
						standardWebURL?: string | null;
						preAffiliatedStandardWebURL?: string | null;
						streamUrl?: string | null;
						retailPrice?: string | null;
						retailPriceValue?: number | null;
						lastChangeRetailPriceValue?: number | null;
						currency?: string | null;
						presentationType: Types.PresentationType;
						monetizationType: Types.MonetizationType;
						availableTo?: any | null;
						dateCreated?: any | null;
						newElementCount?: number | null;
						package: {
							__typename?: 'Package';
							id: string;
							icon: string;
							packageId: number;
							clearName: string;
							shortName: string;
							technicalName: string;
							iconWide: string;
							hasRectangularIcon: boolean;
						};
					} | null;
			  }
			| { __typename?: 'SingleStepSportEvent' }
			| { __typename?: 'SportCompetitionV2' }
			| { __typename?: 'SportCompetitorV2' }
			| { __typename?: 'Url' };
	}>;
	supportingObjects: Array<{
		__typename?: 'ArticleLinkedObject';
		node:
			| { __typename?: 'Article' }
			| { __typename?: 'Author' }
			| { __typename?: 'Bundle' }
			| { __typename?: 'Device' }
			| { __typename?: 'Episode' }
			| {
					__typename: 'GenericTitleList';
					id: string;
					name: string;
					followedlistEntry?: { __typename?: 'FollowedListEntry'; createdAt: any } | null;
					titles: {
						__typename?: 'GenericTitleListConnection';
						totalCount: number;
						pageInfo: {
							__typename?: 'PageInfo';
							endCursor: string;
							hasNextPage: boolean;
							hasPreviousPage: boolean;
							startCursor: string;
						};
						edges?: Array<{
							__typename?: 'GenericTitleListEdge';
							node:
								| {
										__typename?: 'Movie';
										id: string;
										objectId: number;
										objectType: Types.ObjectType;
										seenlistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
										content: {
											__typename?: 'MovieContent';
											title: string;
											fullPath: string;
											originalReleaseYear?: number | null;
											runtime?: number | null;
											shortDescription?: string | null;
											posterUrl?: string | null;
											isReleased: boolean;
											genres: Array<{ __typename?: 'Genre'; shortName: string }>;
											scoring: {
												__typename?: 'Scoring';
												imdbScore?: number | null;
												imdbVotes?: number | null;
												tmdbPopularity?: number | null;
												tmdbScore?: number | null;
											};
											backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
										};
										watchNowOffer?: {
											__typename: 'Offer';
											id: string;
											standardWebURL?: string | null;
											preAffiliatedStandardWebURL?: string | null;
											streamUrl?: string | null;
											retailPrice?: string | null;
											retailPriceValue?: number | null;
											lastChangeRetailPriceValue?: number | null;
											currency?: string | null;
											presentationType: Types.PresentationType;
											monetizationType: Types.MonetizationType;
											availableTo?: any | null;
											dateCreated?: any | null;
											newElementCount?: number | null;
											package: {
												__typename?: 'Package';
												id: string;
												icon: string;
												packageId: number;
												clearName: string;
												shortName: string;
												technicalName: string;
												iconWide: string;
												hasRectangularIcon: boolean;
											};
										} | null;
										watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
										likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
										dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
										offers: Array<{
											__typename?: 'Offer';
											id: string;
											currency?: string | null;
											monetizationType: Types.MonetizationType;
											presentationType: Types.PresentationType;
											retailPrice?: string | null;
											standardWebURL?: string | null;
											preAffiliatedStandardWebURL?: string | null;
											package: { __typename?: 'Package'; shortName: string; id: string };
										}>;
								  }
								| {
										__typename?: 'Show';
										id: string;
										objectId: number;
										objectType: Types.ObjectType;
										seenState: {
											__typename?: 'ShowSeenState';
											progress: number;
											seenEpisodeCount: number;
										};
										content: {
											__typename?: 'ShowContent';
											title: string;
											fullPath: string;
											originalReleaseYear?: number | null;
											runtime?: number | null;
											shortDescription?: string | null;
											posterUrl?: string | null;
											isReleased: boolean;
											genres: Array<{ __typename?: 'Genre'; shortName: string }>;
											scoring: {
												__typename?: 'Scoring';
												imdbScore?: number | null;
												imdbVotes?: number | null;
												tmdbPopularity?: number | null;
												tmdbScore?: number | null;
											};
											backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
										};
										watchNowOffer?: {
											__typename: 'Offer';
											id: string;
											standardWebURL?: string | null;
											preAffiliatedStandardWebURL?: string | null;
											streamUrl?: string | null;
											retailPrice?: string | null;
											retailPriceValue?: number | null;
											lastChangeRetailPriceValue?: number | null;
											currency?: string | null;
											presentationType: Types.PresentationType;
											monetizationType: Types.MonetizationType;
											availableTo?: any | null;
											dateCreated?: any | null;
											newElementCount?: number | null;
											package: {
												__typename?: 'Package';
												id: string;
												icon: string;
												packageId: number;
												clearName: string;
												shortName: string;
												technicalName: string;
												iconWide: string;
												hasRectangularIcon: boolean;
											};
										} | null;
										watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
										likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
										dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
										offers: Array<{
											__typename?: 'Offer';
											id: string;
											currency?: string | null;
											monetizationType: Types.MonetizationType;
											presentationType: Types.PresentationType;
											retailPrice?: string | null;
											standardWebURL?: string | null;
											preAffiliatedStandardWebURL?: string | null;
											package: { __typename?: 'Package'; shortName: string; id: string };
										}>;
								  };
						}> | null;
					};
			  }
			| { __typename?: 'Genre' }
			| {
					__typename: 'Movie';
					id: string;
					objectId: number;
					objectType: Types.ObjectType;
					watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
					content: {
						__typename?: 'MovieContent';
						title: string;
						fullPath: string;
						originalReleaseYear?: number | null;
						runtime?: number | null;
						posterUrl?: string | null;
						isReleased: boolean;
						credits: Array<{
							__typename?: 'Credit';
							role: Types.CreditRole;
							name: string;
							characterName?: string | null;
							personId: number;
						}>;
						genres: Array<{ __typename?: 'Genre'; shortName: string }>;
						scoring: {
							__typename?: 'Scoring';
							imdbScore?: number | null;
							imdbVotes?: number | null;
							tmdbPopularity?: number | null;
							tmdbScore?: number | null;
						};
						externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
						backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
						clips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
						videobusterClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
						dailymotionClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
					};
					fallBackClips: {
						__typename?: 'MovieContent';
						videobusterClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
						dailymotionClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
					};
					watchNowOffer?: {
						__typename: 'Offer';
						id: string;
						standardWebURL?: string | null;
						preAffiliatedStandardWebURL?: string | null;
						streamUrl?: string | null;
						retailPrice?: string | null;
						retailPriceValue?: number | null;
						lastChangeRetailPriceValue?: number | null;
						currency?: string | null;
						presentationType: Types.PresentationType;
						monetizationType: Types.MonetizationType;
						availableTo?: any | null;
						dateCreated?: any | null;
						newElementCount?: number | null;
						package: {
							__typename?: 'Package';
							id: string;
							icon: string;
							packageId: number;
							clearName: string;
							shortName: string;
							technicalName: string;
							iconWide: string;
							hasRectangularIcon: boolean;
						};
					} | null;
			  }
			| { __typename?: 'MultiStepSportEvent' }
			| { __typename?: 'Offer' }
			| { __typename?: 'Package' }
			| { __typename?: 'Page' }
			| { __typename?: 'Person' }
			| { __typename?: 'Season' }
			| {
					__typename: 'Show';
					id: string;
					objectId: number;
					objectType: Types.ObjectType;
					watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
					content: {
						__typename?: 'ShowContent';
						title: string;
						fullPath: string;
						originalReleaseYear?: number | null;
						runtime?: number | null;
						posterUrl?: string | null;
						isReleased: boolean;
						credits: Array<{
							__typename?: 'Credit';
							role: Types.CreditRole;
							name: string;
							characterName?: string | null;
							personId: number;
						}>;
						genres: Array<{ __typename?: 'Genre'; shortName: string }>;
						scoring: {
							__typename?: 'Scoring';
							imdbScore?: number | null;
							imdbVotes?: number | null;
							tmdbPopularity?: number | null;
							tmdbScore?: number | null;
						};
						externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
						backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
						clips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
						videobusterClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
						dailymotionClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
					};
					fallBackClips: {
						__typename?: 'ShowContent';
						videobusterClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
						dailymotionClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
						}>;
					};
					watchNowOffer?: {
						__typename: 'Offer';
						id: string;
						standardWebURL?: string | null;
						preAffiliatedStandardWebURL?: string | null;
						streamUrl?: string | null;
						retailPrice?: string | null;
						retailPriceValue?: number | null;
						lastChangeRetailPriceValue?: number | null;
						currency?: string | null;
						presentationType: Types.PresentationType;
						monetizationType: Types.MonetizationType;
						availableTo?: any | null;
						dateCreated?: any | null;
						newElementCount?: number | null;
						package: {
							__typename?: 'Package';
							id: string;
							icon: string;
							packageId: number;
							clearName: string;
							shortName: string;
							technicalName: string;
							iconWide: string;
							hasRectangularIcon: boolean;
						};
					} | null;
			  }
			| { __typename?: 'SingleStepSportEvent' }
			| { __typename?: 'SportCompetitionV2' }
			| { __typename?: 'SportCompetitorV2' }
			| { __typename?: 'Url' };
	}>;
};

export type LinkedTitle_Movie_Fragment = {
	__typename: 'Movie';
	id: string;
	objectId: number;
	objectType: Types.ObjectType;
	watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	content: {
		__typename?: 'MovieContent';
		title: string;
		fullPath: string;
		originalReleaseYear?: number | null;
		runtime?: number | null;
		posterUrl?: string | null;
		isReleased: boolean;
		credits: Array<{
			__typename?: 'Credit';
			role: Types.CreditRole;
			name: string;
			characterName?: string | null;
			personId: number;
		}>;
		genres: Array<{ __typename?: 'Genre'; shortName: string }>;
		scoring: {
			__typename?: 'Scoring';
			imdbScore?: number | null;
			imdbVotes?: number | null;
			tmdbPopularity?: number | null;
			tmdbScore?: number | null;
		};
		externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
		backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
		clips: Array<{ __typename?: 'Clip'; sourceUrl: string; externalId: string; provider: Types.ClipProvider }>;
		videobusterClips: Array<{
			__typename?: 'Clip';
			sourceUrl: string;
			externalId: string;
			provider: Types.ClipProvider;
		}>;
		dailymotionClips: Array<{
			__typename?: 'Clip';
			sourceUrl: string;
			externalId: string;
			provider: Types.ClipProvider;
		}>;
	};
	fallBackClips: {
		__typename?: 'MovieContent';
		videobusterClips: Array<{
			__typename?: 'Clip';
			sourceUrl: string;
			externalId: string;
			provider: Types.ClipProvider;
		}>;
		dailymotionClips: Array<{
			__typename?: 'Clip';
			sourceUrl: string;
			externalId: string;
			provider: Types.ClipProvider;
		}>;
	};
	watchNowOffer?: {
		__typename: 'Offer';
		id: string;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		lastChangeRetailPriceValue?: number | null;
		currency?: string | null;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		availableTo?: any | null;
		dateCreated?: any | null;
		newElementCount?: number | null;
		package: {
			__typename?: 'Package';
			id: string;
			icon: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			iconWide: string;
			hasRectangularIcon: boolean;
		};
	} | null;
};

export type LinkedTitle_Season_Fragment = {
	__typename: 'Season';
	id: string;
	objectId: number;
	objectType: Types.ObjectType;
	content: {
		__typename?: 'SeasonContent';
		title: string;
		fullPath: string;
		originalReleaseYear?: number | null;
		runtime?: number | null;
		posterUrl?: string | null;
		isReleased: boolean;
		genres: Array<{ __typename?: 'Genre'; shortName: string }>;
		scoring: {
			__typename?: 'Scoring';
			imdbScore?: number | null;
			imdbVotes?: number | null;
			tmdbPopularity?: number | null;
			tmdbScore?: number | null;
		};
		externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
		backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
		clips: Array<{ __typename?: 'Clip'; sourceUrl: string; externalId: string; provider: Types.ClipProvider }>;
		videobusterClips: Array<{
			__typename?: 'Clip';
			sourceUrl: string;
			externalId: string;
			provider: Types.ClipProvider;
		}>;
		dailymotionClips: Array<{
			__typename?: 'Clip';
			sourceUrl: string;
			externalId: string;
			provider: Types.ClipProvider;
		}>;
	};
	fallBackClips: {
		__typename?: 'SeasonContent';
		videobusterClips: Array<{
			__typename?: 'Clip';
			sourceUrl: string;
			externalId: string;
			provider: Types.ClipProvider;
		}>;
		dailymotionClips: Array<{
			__typename?: 'Clip';
			sourceUrl: string;
			externalId: string;
			provider: Types.ClipProvider;
		}>;
	};
	watchNowOffer?: {
		__typename: 'Offer';
		id: string;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		lastChangeRetailPriceValue?: number | null;
		currency?: string | null;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		availableTo?: any | null;
		dateCreated?: any | null;
		newElementCount?: number | null;
		package: {
			__typename?: 'Package';
			id: string;
			icon: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			iconWide: string;
			hasRectangularIcon: boolean;
		};
	} | null;
};

export type LinkedTitle_Show_Fragment = {
	__typename: 'Show';
	id: string;
	objectId: number;
	objectType: Types.ObjectType;
	watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	content: {
		__typename?: 'ShowContent';
		title: string;
		fullPath: string;
		originalReleaseYear?: number | null;
		runtime?: number | null;
		posterUrl?: string | null;
		isReleased: boolean;
		credits: Array<{
			__typename?: 'Credit';
			role: Types.CreditRole;
			name: string;
			characterName?: string | null;
			personId: number;
		}>;
		genres: Array<{ __typename?: 'Genre'; shortName: string }>;
		scoring: {
			__typename?: 'Scoring';
			imdbScore?: number | null;
			imdbVotes?: number | null;
			tmdbPopularity?: number | null;
			tmdbScore?: number | null;
		};
		externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
		backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
		clips: Array<{ __typename?: 'Clip'; sourceUrl: string; externalId: string; provider: Types.ClipProvider }>;
		videobusterClips: Array<{
			__typename?: 'Clip';
			sourceUrl: string;
			externalId: string;
			provider: Types.ClipProvider;
		}>;
		dailymotionClips: Array<{
			__typename?: 'Clip';
			sourceUrl: string;
			externalId: string;
			provider: Types.ClipProvider;
		}>;
	};
	fallBackClips: {
		__typename?: 'ShowContent';
		videobusterClips: Array<{
			__typename?: 'Clip';
			sourceUrl: string;
			externalId: string;
			provider: Types.ClipProvider;
		}>;
		dailymotionClips: Array<{
			__typename?: 'Clip';
			sourceUrl: string;
			externalId: string;
			provider: Types.ClipProvider;
		}>;
	};
	watchNowOffer?: {
		__typename: 'Offer';
		id: string;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		lastChangeRetailPriceValue?: number | null;
		currency?: string | null;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		availableTo?: any | null;
		dateCreated?: any | null;
		newElementCount?: number | null;
		package: {
			__typename?: 'Package';
			id: string;
			icon: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			iconWide: string;
			hasRectangularIcon: boolean;
		};
	} | null;
};

export type LinkedTitleFragment = LinkedTitle_Movie_Fragment | LinkedTitle_Season_Fragment | LinkedTitle_Show_Fragment;

export type LinkedListFragment = {
	__typename: 'GenericTitleList';
	id: string;
	name: string;
	followedlistEntry?: { __typename?: 'FollowedListEntry'; createdAt: any } | null;
	titles: {
		__typename?: 'GenericTitleListConnection';
		totalCount: number;
		pageInfo: {
			__typename?: 'PageInfo';
			endCursor: string;
			hasNextPage: boolean;
			hasPreviousPage: boolean;
			startCursor: string;
		};
		edges?: Array<{
			__typename?: 'GenericTitleListEdge';
			node:
				| {
						__typename?: 'Movie';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						seenlistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						content: {
							__typename?: 'MovieContent';
							title: string;
							fullPath: string;
							originalReleaseYear?: number | null;
							runtime?: number | null;
							shortDescription?: string | null;
							posterUrl?: string | null;
							isReleased: boolean;
							genres: Array<{ __typename?: 'Genre'; shortName: string }>;
							scoring: {
								__typename?: 'Scoring';
								imdbScore?: number | null;
								imdbVotes?: number | null;
								tmdbPopularity?: number | null;
								tmdbScore?: number | null;
							};
							backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
						};
						watchNowOffer?: {
							__typename: 'Offer';
							id: string;
							standardWebURL?: string | null;
							preAffiliatedStandardWebURL?: string | null;
							streamUrl?: string | null;
							retailPrice?: string | null;
							retailPriceValue?: number | null;
							lastChangeRetailPriceValue?: number | null;
							currency?: string | null;
							presentationType: Types.PresentationType;
							monetizationType: Types.MonetizationType;
							availableTo?: any | null;
							dateCreated?: any | null;
							newElementCount?: number | null;
							package: {
								__typename?: 'Package';
								id: string;
								icon: string;
								packageId: number;
								clearName: string;
								shortName: string;
								technicalName: string;
								iconWide: string;
								hasRectangularIcon: boolean;
							};
						} | null;
						watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						offers: Array<{
							__typename?: 'Offer';
							id: string;
							currency?: string | null;
							monetizationType: Types.MonetizationType;
							presentationType: Types.PresentationType;
							retailPrice?: string | null;
							standardWebURL?: string | null;
							preAffiliatedStandardWebURL?: string | null;
							package: { __typename?: 'Package'; shortName: string; id: string };
						}>;
				  }
				| {
						__typename?: 'Show';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						seenState: { __typename?: 'ShowSeenState'; progress: number; seenEpisodeCount: number };
						content: {
							__typename?: 'ShowContent';
							title: string;
							fullPath: string;
							originalReleaseYear?: number | null;
							runtime?: number | null;
							shortDescription?: string | null;
							posterUrl?: string | null;
							isReleased: boolean;
							genres: Array<{ __typename?: 'Genre'; shortName: string }>;
							scoring: {
								__typename?: 'Scoring';
								imdbScore?: number | null;
								imdbVotes?: number | null;
								tmdbPopularity?: number | null;
								tmdbScore?: number | null;
							};
							backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
						};
						watchNowOffer?: {
							__typename: 'Offer';
							id: string;
							standardWebURL?: string | null;
							preAffiliatedStandardWebURL?: string | null;
							streamUrl?: string | null;
							retailPrice?: string | null;
							retailPriceValue?: number | null;
							lastChangeRetailPriceValue?: number | null;
							currency?: string | null;
							presentationType: Types.PresentationType;
							monetizationType: Types.MonetizationType;
							availableTo?: any | null;
							dateCreated?: any | null;
							newElementCount?: number | null;
							package: {
								__typename?: 'Package';
								id: string;
								icon: string;
								packageId: number;
								clearName: string;
								shortName: string;
								technicalName: string;
								iconWide: string;
								hasRectangularIcon: boolean;
							};
						} | null;
						watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						offers: Array<{
							__typename?: 'Offer';
							id: string;
							currency?: string | null;
							monetizationType: Types.MonetizationType;
							presentationType: Types.PresentationType;
							retailPrice?: string | null;
							standardWebURL?: string | null;
							preAffiliatedStandardWebURL?: string | null;
							package: { __typename?: 'Package'; shortName: string; id: string };
						}>;
				  };
		}> | null;
	};
};

export type AuthorDataFragment = {
	__typename?: 'AuthorContent';
	bio: string;
	clearName: string;
	imageUrl?: string | null;
	fullPath: string;
	alumniOf: Array<string>;
	brand: string;
	languagesSpoken: Array<any>;
	occupation: Array<string>;
	publications?: Array<{ __typename?: 'AuthorSpecificLink'; name: string; url: string }> | null;
	socialHandles?: Array<{ __typename?: 'AuthorSpecificLink'; name: string; url: string }> | null;
};

export const AuthorDataFragmentDoc = gql`
	fragment AuthorData on AuthorContent {
		bio
		clearName
		imageUrl
		fullPath
		publications {
			name
			url
		}
		socialHandles {
			name
			url
		}
		alumniOf
		brand
		languagesSpoken
		occupation
	}
`;
export const LinkedTitleFragmentDoc = gql`
	fragment LinkedTitle on MovieOrShowOrSeason {
		id
		__typename
		objectId
		objectType
		content(country: $country, language: $language) {
			title
			fullPath
			genres {
				shortName
			}
			originalReleaseYear
			runtime
			scoring {
				imdbScore
				imdbVotes
				tmdbPopularity
				tmdbScore
			}
			externalIds {
				imdbId
			}
			... on MovieOrShowContent {
				credits {
					role
					name
					characterName
					personId
				}
			}
			posterUrl
			isReleased
			backdrops {
				backdropUrl
			}
			clips {
				sourceUrl
				externalId
				provider
			}
			videobusterClips: clips(providers: [VIDEOBUSTER]) {
				sourceUrl
				externalId
				provider
			}
			dailymotionClips: clips(providers: [DAILYMOTION]) {
				sourceUrl
				externalId
				provider
			}
		}
		fallBackClips: content(country: "US", language: "en") {
			videobusterClips: clips(providers: [VIDEOBUSTER]) {
				sourceUrl
				externalId
				provider
			}
			dailymotionClips: clips(providers: [DAILYMOTION]) {
				sourceUrl
				externalId
				provider
			}
		}
		watchNowOffer(country: $country, platform: WEB) {
			...WatchNowOffer
		}
		... on MovieOrShow {
			watchlistEntryV2 {
				createdAt
			}
		}
	}
	${WatchNowOfferFragmentDoc}
`;
export const LinkedListFragmentDoc = gql`
	fragment LinkedList on GenericTitleList {
		id
		__typename
		name
		followedlistEntry {
			createdAt
		}
		titles(country: $country, first: $first, filter: $filter, sortBy: NATURAL, after: $after) {
			totalCount
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
				startCursor
			}
			edges {
				node {
					...EditorialListTitle
				}
			}
		}
	}
	${EditorialListTitleFragmentDoc}
`;
export const ArticleFragmentDoc = gql`
	fragment Article on Article {
		author {
			id
			content(country: $country, language: $language) {
				...AuthorData
			}
		}
		content {
			title
			subtitle
			paragraph1
			paragraph2
			synopsis
			whereToWatch
			whatToKnow
			productionNews
			articleType
			createdAt
			updatedAt
			mainImageEntityBackdrop(profile: $profile, format: $format) {
				backdropUrl
			}
		}
		mainObjects: linkedObjects(priority: 1) {
			__typename
			node {
				__typename
				... on MovieOrShowOrSeason {
					...LinkedTitle
				}
				... on GenericTitleList {
					...LinkedList
				}
			}
		}
		secondaryObjects: linkedObjects(priority: 2) {
			node {
				... on MovieOrShowOrSeason {
					...LinkedTitle
				}
				... on GenericTitleList {
					...LinkedList
				}
			}
		}
		supportingObjects: linkedObjects(priority: 3) {
			node {
				... on MovieOrShow {
					...LinkedTitle
				}
				... on GenericTitleList {
					...LinkedList
				}
			}
		}
	}
	${AuthorDataFragmentDoc}
	${LinkedTitleFragmentDoc}
	${LinkedListFragmentDoc}
`;
export const GetArticleByUrlDocument = gql`
	query GetArticleByUrl(
		$fullPath: String!
		$country: Country!
		$language: Language!
		$first: Int!
		$filter: TitleFilter
		$after: String
		$profile: BackdropProfile
		$format: ImageFormat
	) {
		urlV2(fullPath: $fullPath) {
			id
			node {
				id
				... on Article {
					...Article
				}
			}
		}
	}
	${ArticleFragmentDoc}
`;
