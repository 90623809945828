import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
import { WatchNowOfferFragmentDoc } from './WatchNowOffer.fragment';
export type EditorialListTitle_Movie_Fragment = {
	__typename?: 'Movie';
	id: string;
	objectId: number;
	objectType: Types.ObjectType;
	seenlistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	content: {
		__typename?: 'MovieContent';
		title: string;
		fullPath: string;
		originalReleaseYear?: number | null;
		runtime?: number | null;
		shortDescription?: string | null;
		posterUrl?: string | null;
		isReleased: boolean;
		genres: Array<{ __typename?: 'Genre'; shortName: string }>;
		scoring: {
			__typename?: 'Scoring';
			imdbScore?: number | null;
			imdbVotes?: number | null;
			tmdbPopularity?: number | null;
			tmdbScore?: number | null;
		};
		backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
	};
	watchNowOffer?: {
		__typename: 'Offer';
		id: string;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		lastChangeRetailPriceValue?: number | null;
		currency?: string | null;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		availableTo?: any | null;
		dateCreated?: any | null;
		newElementCount?: number | null;
		package: {
			__typename?: 'Package';
			id: string;
			icon: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			iconWide: string;
			hasRectangularIcon: boolean;
		};
	} | null;
	watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	offers: Array<{
		__typename?: 'Offer';
		id: string;
		currency?: string | null;
		monetizationType: Types.MonetizationType;
		presentationType: Types.PresentationType;
		retailPrice?: string | null;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		package: { __typename?: 'Package'; shortName: string; id: string };
	}>;
};

export type EditorialListTitle_Show_Fragment = {
	__typename?: 'Show';
	id: string;
	objectId: number;
	objectType: Types.ObjectType;
	seenState: { __typename?: 'ShowSeenState'; progress: number; seenEpisodeCount: number };
	content: {
		__typename?: 'ShowContent';
		title: string;
		fullPath: string;
		originalReleaseYear?: number | null;
		runtime?: number | null;
		shortDescription?: string | null;
		posterUrl?: string | null;
		isReleased: boolean;
		genres: Array<{ __typename?: 'Genre'; shortName: string }>;
		scoring: {
			__typename?: 'Scoring';
			imdbScore?: number | null;
			imdbVotes?: number | null;
			tmdbPopularity?: number | null;
			tmdbScore?: number | null;
		};
		backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
	};
	watchNowOffer?: {
		__typename: 'Offer';
		id: string;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		lastChangeRetailPriceValue?: number | null;
		currency?: string | null;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		availableTo?: any | null;
		dateCreated?: any | null;
		newElementCount?: number | null;
		package: {
			__typename?: 'Package';
			id: string;
			icon: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			iconWide: string;
			hasRectangularIcon: boolean;
		};
	} | null;
	watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	offers: Array<{
		__typename?: 'Offer';
		id: string;
		currency?: string | null;
		monetizationType: Types.MonetizationType;
		presentationType: Types.PresentationType;
		retailPrice?: string | null;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		package: { __typename?: 'Package'; shortName: string; id: string };
	}>;
};

export type EditorialListTitleFragment = EditorialListTitle_Movie_Fragment | EditorialListTitle_Show_Fragment;

export const EditorialListTitleFragmentDoc = gql`
	fragment EditorialListTitle on MovieOrShow {
		id
		objectId
		objectType
		content(country: $country, language: $language) {
			title
			fullPath
			originalReleaseYear
			runtime
			shortDescription
			genres {
				shortName
			}
			scoring {
				imdbScore
				imdbVotes
				tmdbPopularity
				tmdbScore
			}
			posterUrl
			isReleased
			backdrops {
				backdropUrl
			}
		}
		watchNowOffer(country: $country, platform: WEB) {
			...WatchNowOffer
		}
		watchlistEntryV2 {
			createdAt
		}
		likelistEntry {
			createdAt
		}
		dislikelistEntry {
			createdAt
		}
		... on Movie {
			seenlistEntry {
				createdAt
			}
		}
		... on Show {
			seenState(country: $country) {
				progress
				seenEpisodeCount
			}
		}
		offers(country: $country, platform: WEB, filter: { preAffiliate: true }) {
			id
			currency
			monetizationType
			package {
				shortName
				id
			}
			presentationType
			retailPrice(language: $language)
			standardWebURL
			preAffiliatedStandardWebURL
		}
	}
	${WatchNowOfferFragmentDoc}
`;
