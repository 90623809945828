import { computed, defineComponent } from 'vue';
import { MaybeRefOrGetter, toValue } from '@vueuse/core';

import { useRoute } from 'vue-router/composables';
import { useQuery } from '@/helpers/composables/useApollo';
import { useLanguageStore } from '@/helpers/composables/useStores';

import {
	GuideEntryFragment,
	GetGuideArticlesDocument,
	GetGuideArticlesQuery,
	GetGuideArticlesQueryVariables,
} from '@/pages/graphql/queries/GetGuideArticles.query';

import { ArticleType, BackdropProfile, ImageFormat } from '@/@types/graphql-types';

interface UseArticleListOptions {
	/** Page number to get. */
	page?: MaybeRefOrGetter<number>;

	/** Number of items fetched for each page. */
	pageSize?: MaybeRefOrGetter<number>;

	/** ID of the title from which to get linked articles. */
	entityId?: MaybeRefOrGetter<string | undefined>;

	isTitle?: boolean;

	profile?: BackdropProfile;

	format?: ImageFormat;

	/** ID of the authors to filter the articles for. */
	authorIds?: string[];
}

/** Handles fetching and paginating multiple articles for a locale or related to a title. */
export function useArticleList({
	page = 0,
	pageSize = 30,
	entityId = undefined,
	isTitle = false,
	profile = BackdropProfile.S640,
	format = ImageFormat.Png,
	authorIds = [],
}: UseArticleListOptions) {
	const { language, country } = useLanguageStore();

	const queryVariables = computed<GetGuideArticlesQueryVariables>(() => {
		const entity = toValue(entityId);
		const variables = {
			country: country.value,
			language: language.value,
			first: toValue(pageSize),
			offset: Math.max(0, toValue(page) - 1) * toValue(pageSize),
			profile: toValue(profile),
			format: toValue(format),
			filter: {
				authorIds,
				articleTypes: [ArticleType.NewsArticle],
				linkedEntityIds: [] as string[],
				...(isTitle && entityId && { titleListEntryEntityId: entity }),
			},
		};

		if (entity && !isTitle) {
			variables.filter.linkedEntityIds.push(entity);
		}

		return variables;
	});

	const { result, fetchMore } = useQuery<GetGuideArticlesQuery>(GetGuideArticlesDocument, queryVariables, {
		errorPolicy: 'all',
		debug: false,
	});

	const route = useRoute();
	const articles = computed<GuideEntryFragment[]>(() =>
		(result.value?.articles.edges ?? [])
			.map(({ node }) => node)
			.filter(article => article.content.fullPath !== route.fullPath)
	);

	const totalCount = computed(() => result.value?.articles.totalCount ?? 0);

	const lastPage = computed(() => Math.ceil(totalCount.value / toValue(pageSize)));

	async function fetchPage() {
		const size = toValue(pageSize);
		await fetchMore({
			variables: {
				first: size,
				offset: (toValue(page) + 1) * size,
			},
			updateQuery: (previousResult, { fetchMoreResult }) => fetchMoreResult ?? previousResult,
		});
	}

	return {
		articles,
		lastPage,
		totalCount,
		fetchPage,
	};
}

/** Renderless version */
export default defineComponent({
	name: 'UseArticleList',
	setup(props, { slots }) {
		/*
			Don't put `useArticleList(props) directly into `slots.default`,
			it causes an infinite loop error.
		*/
		const articleList = useArticleList(props);
		return () => slots.default?.(articleList) ?? slots[0];
	},
});
